import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  UserOutlined,
  SettingOutlined,
  DollarCircleOutlined,
  BarChartOutlined,
  ReadOutlined,
  ShopOutlined,
  PhoneOutlined,
  BellOutlined,
  StockOutlined,
  DatabaseOutlined,
  WalletOutlined
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => {
      if (localStorage.getItem("token")) {
        return <Redirect to="/dashboard/today" />
      } else {
        return <Redirect to="/login" />
      }
    },
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/dashboard",
    sidebar: {
      icon: <BarChartOutlined />,
      label: "대시보드 관리",
    },
    children: [
      {
        exact: true,
        path: "/today",
        sidebar: {
          label: "당일 카운트",
        },
        component: loadable(() => import("../pages/dashBoard/Today")),
      },
      {
        exact: true,
        path: "/cafe",
        sidebar: {
          label: "카페 리포트",
        },
        component: loadable(() => import("../pages/dashBoard/Cafe")),
      },
      {
        exact: true,
        path: "/market",
        sidebar: {
          label: "마켓 리포트",
        },
        component: loadable(() => import("../pages/dashBoard/Market")),
      },
    ],
  },
  {
    exact: true,
    path: "/user",
    sidebar: {
      icon: <UserOutlined />,
      label: "회원 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "회원 목록",
        },
        component: loadable(() => import("../pages/user/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/user/Detail")),
      },
      {
        exact: true,
        path: "/withdraw",
        sidebar: {
          label: "탈퇴 회원 목록",
        },
        component: loadable(() => import("../pages/user/WithdrawList")),
      },
      {
        exact: true,
        visible: false,
        path: "/withdraw/detail/:id",
        component: loadable(() => import("../pages/user/WithdrawDetail")),
      },
/*      {
        exact: true,
        path: "/coffee/test",
        sidebar: {
          label: "커피 취향 테스트 결과 목록",
        },
        component: loadable(() => import("../pages/user/CoffeeTestList")),
      },*/
    ],
  },
  {
    exact: true,
    path: "/cafe",
    sidebar: {
      icon: <ShopOutlined />,
      label: "매장 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "매장 목록",
        },
        component: loadable(() => import("../pages/cafe/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/cafe/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/cafe/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/menu/:id",
        component: loadable(() => import("../pages/cafe/menu/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/menu/category/:id",
        component: loadable(() => import("../pages/cafe/menu/CategoryList")),
      },
      {
        exact: true,
        visible: false,
        path: "/menu/option/:id",
        component: loadable(() => import("../pages/cafe/menu/OptionList")),
      },
      {
        exact: true,
        visible: false,
        path: "/menu/option/category/:id",
        component: loadable(() => import("../pages/cafe/menu/OptionCategoryList")),
      },
      {
        exact: true,
        path: "/request",
        sidebar: {
          label: "메뉴 심사 요청 목록",
        },
        component: loadable(() => import("../pages/cafe/request/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/request/detail/:id",
        component: loadable(() => import("../pages/cafe/request/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/market",
    sidebar: {
      icon: <ReadOutlined />,
      label: "상품 관리",
    },
    children: [
      {
        exact: true,
        path: "/product",
        sidebar: {
          label: "마켓 상품 관리",
        },
        component: loadable(() => import("../pages/market/products/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/product/detail/:id",
        component: loadable(() => import("../pages/market/products/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/product/modify/:id",
        component: loadable(() => import("../pages/market/products/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/product/register",
        component: loadable(() => import("../pages/market/products/Register")),
      },
      {
        exact: true,
        path: "",
        sidebar: {
          label: "입점사 관리",
        },
        component: loadable(() => import("../pages/market/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/market/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/modify/:id",
        component: loadable(() => import("../pages/market/Modify")),
      },
    ],
  },
  {
    exact: true,
    path: "/calculate/cafe",
    sidebar: {
      icon: <DollarCircleOutlined />,
      label: "주문/정산 (매장)",
    },
    children: [
      {
        exact: true,
        path: "/fee",
        sidebar: {
          label: "수수료 및 배송비 설정",
        },
        component: loadable(() => import("../pages/calculate/cafe/Fee")),
      },
      {
        exact: true,
        path: "/order",
        sidebar: {
          label: "매장 주문 목록",
        },
        component: loadable(() => import("../pages/calculate/cafe/order/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/order/detail/:id",
        component: loadable(() => import("../pages/calculate/cafe/order/Detail")),
      },
      {
        exact: true,
        path: "/schedule",
        sidebar: {
          label: "정산 예정 목록",
        },
        component: loadable(() => import("../pages/calculate/cafe/schedule/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/schedule/detail/:id",
        component: loadable(() => import("../pages/calculate/cafe/schedule/Detail")),
      },
      {
        exact: true,
        path: "",
        sidebar: {
          label: "정산 완료 목록",
        },
        component: loadable(() => import("../pages/calculate/cafe/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/calculate/cafe/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/calculate/market",
    sidebar: {
      icon: <DollarCircleOutlined />,
      label: "주문/정산 (마켓)",
    },
    children: [
      {
        exact: true,
        path: "/order",
        sidebar: {
          label: "주문 번호",
        },
        component: loadable(() => import("../pages/calculate/market/order/OrderList")),
      },
      {
        exact: true,
        path: "/order/status",
        sidebar: {
          label: "주문 품목",
        },
        component: loadable(() => import("../pages/calculate/market/order/OrderStatusList")),
      },
      {
        exact: true,
        visible: false,
        path: "/order/detail/:id",
        component: loadable(() => import("../pages/calculate/market/order/OrderDetail")),
      },
      {
        exact: true,
        path: "/schedule",
        sidebar: {
          label: "정산 예정 목록",
        },
        component: loadable(() => import("../pages/calculate/market/schedule/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/schedule/detail/:id",
        component: loadable(() => import("../pages/calculate/market/schedule/Detail")),
      },
      {
        exact: true,
        path: "",
        sidebar: {
          label: "정산 완료 목록",
        },
        component: loadable(() => import("../pages/calculate/market/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/calculate/market/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/calculate/subscribe",
    sidebar: {
      icon: <DollarCircleOutlined />,
      label: "주문/정산 (구독)",
    },
    children: [
      {
        exact: true,
        path: "/regular",
        sidebar: {
          label: "정기 구독 목록",
        },
        component: loadable(() => import("../pages/calculate/subscribe/regular/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/regular/detail/:id",
        component: loadable(() => import("../pages/calculate/subscribe/regular/Detail")),
      },
      {
        exact: true,
        path: "/order",
        sidebar: {
          label: "구독 주문 목록",
        },
        component: loadable(() => import("../pages/calculate/subscribe/order/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/order/detail/:id",
        component: loadable(() => import("../pages/calculate/subscribe/order/Detail")),
      },
      {
        exact: true,
        path: "/schedule",
        sidebar: {
          label: "정산 예정 목록",
        },
        component: loadable(() => import("../pages/calculate/subscribe/schedule/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/schedule/detail/:id",
        component: loadable(() => import("../pages/calculate/subscribe/schedule/Detail")),
      },
      {
        exact: true,
        path: "",
        sidebar: {
          label: "정산 완료 목록",
        },
        component: loadable(() => import("../pages/calculate/subscribe/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/calculate/subscribe/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/board",
    sidebar: {
      icon: <ReadOutlined />,
      label: "게시글 관리",
    },
    children: [
      {
        exact: true,
        path: "/review/cafe",
        sidebar: {
          label: "매장 리뷰 목록",
        },
        component: loadable(() => import("../pages/board/review/cafe/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/review/cafe/detail/:id",
        component: loadable(() => import("../pages/board/review/cafe/Detail")),
      },
      {
        exact: true,
        path: "/review/market",
        sidebar: {
          label: "상품 리뷰 목록",
        },
        component: loadable(() => import("../pages/board/review/market/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/review/market/detail/:id",
        component: loadable(() => import("../pages/board/review/market/Detail")),
      },
      {
        exact: true,
        path: "",
        sidebar: {
          label: "피드 목록",
        },
        component: loadable(() => import("../pages/board/feed/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/board/feed/Detail")),
      },
      {
        exact: true,
        path: "/talk",
        sidebar: {
          label: "톡 목록",
        },
        component: loadable(() => import("../pages/board/talk/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/talk/detail/:id",
        component: loadable(() => import("../pages/board/talk/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/category",
    sidebar: {
      icon: <DatabaseOutlined />,
      label: "카테고리 관리",
    },
    children: [
      {
        exact: true,
        path: "/cafe",
        sidebar: {
          label: "매장 카테고리 관리",
        },
        component: loadable(() => import("../pages/category/cafe/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/cafe/modify/:id",
        component: loadable(() => import("../pages/category/cafe/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/cafe/register",
        component: loadable(() => import("../pages/category/cafe/Register")),
      },
      {
        exact: true,
        path: "/market",
        sidebar: {
          label: "마켓 상품 카테고리 관리",
        },
        component: loadable(() => import("../pages/category/market/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/market/modify/:id",
        component: loadable(() => import("../pages/category/market/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/market/register",
        component: loadable(() => import("../pages/category/market/Register")),
      },
      {
        exact: true,
        path: "/coffee",
        sidebar: {
          label: "커피 ID 관리",
        },
        component: loadable(() => import("../pages/category/coffee/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/coffee/modify/:id",
        component: loadable(() => import("../pages/category/coffee/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/coffee/register",
        component: loadable(() => import("../pages/category/coffee/Register")),
      },
    ],
  },
  {
    exact: true,
    path: "/coupon",
    sidebar: {
      icon: <WalletOutlined />,
      label: "포인트/쿠폰 관리",
    },
    children: [
      {
        exact: true,
        path: "/admin",
        sidebar: {
          label: "관리자 쿠폰 관리",
        },
        component: loadable(() => import("../pages/coupon/admin/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/admin/register",
        component: loadable(() => import("../pages/coupon/admin/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/admin/modify/:id",
        component: loadable(() => import("../pages/coupon/admin/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/admin/history/:id",
        component: loadable(() => import("../pages/coupon/admin/HistoryList")),
      },
      {
        exact: true,
        path: "/store",
        sidebar: {
          label: "매장 쿠폰 관리",
        },
        component: loadable(() => import("../pages/coupon/cafe/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/store/register",
        component: loadable(() => import("../pages/coupon/cafe/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/store/modify/:id",
        component: loadable(() => import("../pages/coupon/cafe/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/store/history/:id",
        component: loadable(() => import("../pages/coupon/cafe/HistoryList")),
      },
      {
        exact: true,
        path: "/point/config",
        sidebar: {
          label: "포인트 지급 현황",
        },
        component: loadable(() => import("../pages/point/Config")),
      },
      {
        exact: true,
        path: "/point",
        sidebar: {
          label: "포인트 지급/차감 내역",
        },
        component: loadable(() => import("../pages/point/List")),
      },
    ],
  },
  {
    exact: true,
    path: "/site",
    sidebar: {
      icon: <SettingOutlined />,
      label: "사이트 관리",
    },
    children: [
      {
        exact: true,
        path: "/main/banner",
        sidebar: {
          label: "메인 배너 관리",
        },
        component: loadable(() => import("../pages/site/banner/main/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/main/banner/register",
        component: loadable(() => import("../pages/site/banner/main/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/main/banner/modify/:id",
        component: loadable(() => import("../pages/site/banner/main/Modify")),
      },
      {
        exact: true,
        path: "/sub/banner",
        sidebar: {
          label: "메인 서브 배너 관리",
        },
        component: loadable(() => import("../pages/site/banner/sub/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/sub/banner/register",
        component: loadable(() => import("../pages/site/banner/sub/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/sub/banner/modify/:id",
        component: loadable(() => import("../pages/site/banner/sub/Modify")),
      },
      {
        exact: true,
        path: "/market/banner",
        sidebar: {
          label: "마켓 배너 관리",
        },
        component: loadable(() => import("../pages/site/banner/market/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/market/banner/register",
        component: loadable(() => import("../pages/site/banner/market/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/market/banner/modify/:id",
        component: loadable(() => import("../pages/site/banner/market/Modify")),
      },
      {
        exact: true,
        path: "/api",
        sidebar: {
          label: "api 관리",
        },
        component: loadable(() => import("../pages/site/api/List")),
      },
      {
        exact: true,
        path: "/term",
        sidebar: {
          label: "약관 관리",
        },
        component: loadable(() => import("../pages/site/term/List")),
      },
      {
        exact: true,
        path: "/today",
        sidebar: {
          label: "오늘의 커픽 관리",
        },
        component: loadable(() => import("../pages/site/today/List")),
      },
    ],
  },
  {
    exact: true,
    path: "/cs",
    sidebar: {
      icon: <PhoneOutlined />,
      label: "고객센터",
    },
    children: [
      {
        exact: true,
        path: "/qna",
        sidebar: {
          label: "1:1문의",
        },
        component: loadable(() => import("../pages/cs/qna/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/qna/detail/:id",
        component: loadable(() => import("../pages/cs/qna/Detail")),
      },
      {
        exact: true,
        path: "/order",
        sidebar: {
          label: "주문 관련 문의",
        },
        component: loadable(() => import("../pages/cs/order/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/order/detail/:id",
        component: loadable(() => import("../pages/cs/order/Detail")),
      },
      {
        exact: true,
        path: "/notice/cafe",
        sidebar: {
          label: "매장 공지사항",
        },
        component: loadable(() => import("../pages/cs/notice/cafe/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/notice/cafe/detail/:id",
        component: loadable(() => import("../pages/cs/notice/cafe/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/notice/cafe/register",
        component: loadable(() => import("../pages/cs/notice/cafe/Register")),
      },
      {
        exact: true,
        path: "/notice/user",
        sidebar: {
          label: "사용자 공지사항",
        },
        component: loadable(() => import("../pages/cs/notice/user/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/notice/user/detail/:id",
        component: loadable(() => import("../pages/cs/notice/user/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/notice/user/register",
        component: loadable(() => import("../pages/cs/notice/user/Register")),
      },
      {
        exact: true,
        path: "/report/review",
        sidebar: {
          label: "리뷰 신고 목록",
        },
        component: loadable(() => import("../pages/cs/report/ReviewList")),
      },
      {
        exact: true,
        path: "/report/community",
        sidebar: {
          label: "게시글 신고 목록",
        },
        component: loadable(() => import("../pages/cs/report/CommunityList")),
      },
      {
        exact: true,
        path: "/report/comment",
        sidebar: {
          label: "댓글 신고 목록",
        },
        component: loadable(() => import("../pages/cs/report/CommentList")),
      },
    ],
  },
  {
    exact: true,
    path: "/alarm",
    sidebar: {
      icon: <BellOutlined />,
      label: "알림",
    },
    children: [
      {
        exact: true,
        path: "/issue",
        sidebar: {
          label: "알림 발송",
        },
        component: loadable(() => import("../pages/alarm/Issue")),
      },
      {
        exact: true,
        path: "/history",
        sidebar: {
          label: "알림 발송 내역",
        },
        component: loadable(() => import("../pages/alarm/HistoryList")),
      },
    ],
  },
  {
    exact: true,
    path: "/stats",
    sidebar: {
      icon: <StockOutlined />,
      label: "통계",
    },
    children: [
      {
        exact: true,
        path: "/cafe/sale",
        sidebar: {
          label: "매장 매출 통계",
        },
        component: loadable(() => import("../pages/stats/cafe/saleList")),
      },
      {
        exact: true,
        path: "/cafe/order",
        sidebar: {
          label: "매장 주문 통계",
        },
        component: loadable(() => import("../pages/stats/cafe/OrderList")),
      },
      {
        exact: true,
        path: "/cafe/menu",
        sidebar: {
          label: "매장 메뉴 통계",
        },
        component: loadable(() => import("../pages/stats/cafe/MenuList")),
      },
      {
        exact: true,
        path: "/cafe/status",
        sidebar: {
          label: "매장 등록/삭제 통계",
        },
        component: loadable(() => import("../pages/stats/cafe/statusList")),
      },
      {
        exact: true,
        path: "/market/sale",
        sidebar: {
          label: "마켓 매출 통계",
        },
        component: loadable(() => import("../pages/stats/market/saleList")),
      },
      {
        exact: true,
        path: "/market/order",
        sidebar: {
          label: "마켓 주문 통계",
        },
        component: loadable(() => import("../pages/stats/market/OrderList")),
      },
      {
        exact: true,
        path: "/market/menu",
        sidebar: {
          label: "마켓 상품별 통계",
        },
        component: loadable(() => import("../pages/stats/market/ProductList")),
      },
      {
        exact: true,
        path: "/market/status",
        sidebar: {
          label: "마켓 등록/삭제 통계",
        },
        component: loadable(() => import("../pages/stats/market/statusList")),
      },
      {
        exact: true,
        path: "/user",
        sidebar: {
          label: "회원 통계",
        },
        component: loadable(() => import("../pages/stats/UserList")),
      },
      {
        exact: true,
        path: "/user/activity",
        sidebar: {
          label: "회원 활동 통계",
        },
        component: loadable(() => import("../pages/stats/UserActivityList")),
      },
      {
        exact: true,
        path: "/user/point",
        sidebar: {
          label: "포인트 통계",
        },
        component: loadable(() => import("../pages/stats/PointList")),
      },
    ],
  },
];
