import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import App from "./App";
import { RecoilRoot } from "recoil";
import { Helmet } from 'react-helmet';

import "antd/dist/antd.css";
import "./styles/reset.css";

ReactDOM.render(
  <RecoilRoot>
      <Helmet>
          <meta name="viewport" content="min-width=device-width, initial-scale=0.1, maximum-scale=0.1, user-scalable=1" />
      </Helmet>
    <App />
  </RecoilRoot>,
  document.getElementById("root")
);

serviceWorker.unregister();
